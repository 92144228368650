@import '../../assets/styles/colors';

.product-mini-card{
    width: 200px;
    height: 200px;
    border-radius: 5px;
    display: inline-block;
    transition: ease all .3s;
    cursor: pointer;
    margin: 15px;
    background: rgba(0, 0, 0, .05);
    padding:10px;
    position: relative;

    h1, h2, h3, h4, h5, h6 {
        margin:0 !important;
    }

    .product-price{
        position: absolute;
        bottom: 10px;
        right: 10px;
        margin: 0;
        color: $color-red;
    }

    &:hover, &.active{
        background: $color-red;
        box-shadow: 8px 8px 16px $color-red-05;
        h1, h2, h3, h4, h5, h6, span {
            color:#fff !important;
        }
    }
}