.amount-tile{
	padding: 15px;
	background: #fff;
	border:solid 1px rgba(0, 0, 0, .05);
	box-shadow: 3px 3px 6px rgba(0, 0, 0, .3);
	border-radius: 5px;
	text-align: center;
	width: auto;

	h3{
		 margin:0 !important;
	}
}