$color-blue: #679aff;
$color-blue-01: rgba(103, 154, 255, 0.1);
$color-red: #f65058;
$color-red-05: rgba(246, 80, 88, 0.5);
$color-red-02: rgba(246, 80, 88, 0.2);
$color-red-01: rgba(246, 80, 88, 0.1);
$color-yellow: #fbde44;
$color-yellow-05: rgba(251, 222, 68, 0.5);
$color-black: #28334a;
$color-black-2: #707070;
$color-dark-grey-1: #656a74;
$color-dark-grey-2: #9d9d9d;
$color-light-grey-1: #e8e8e8;
$color-light-grey-2: #f6f6f6;
$color-light-grey-3: #efefef;
$color-white: #fcfcfc;
