@import '../../assets/styles/colors';

.tile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	border: solid 1px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	transition: ease all 0.3s;
	cursor: pointer;

	&.active {
		background: $color-yellow;
		border-color: $color-yellow;
	}
}
