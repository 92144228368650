@import '../../assets/styles/colors';

.product-credit-note{
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    margin-bottom: 15px;
    padding:5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .returned{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background: $color-yellow;
        border-radius: 50%;
        margin-left: -20px;
    }

    .icons{
        display: flex;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            color:#fff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #494949;
            margin-left: 10px;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
        }
    }
}