@import '../../assets/styles/colors';

.paused-ticket {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	border-radius: 3px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin-bottom: 10px;
	padding: 15px;

	.name {
		width: 300px;
		h2 {
			margin: 0;
			font-size: 18px;
			font-family: SF UI Display Heavy;
			color: $color-dark-grey-1;
		}
	}

	.actions {
		display: flex;

		.btn {
			margin-left: 15px;
			color: #fff;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			cursor: pointer;

			&:nth-child(1) {
				background: #679aff;
			}

			&:nth-child(2) {
				background: $color-red;
			}
		}
	}
}
